/*===================================================
	DEFAULT
===================================================*/

html, body {
    background-color: $light;
    height: 100%;
}

#wrapper {
    position: relative;
    min-height: 100%;
}