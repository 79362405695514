/*===================================================
	BUTTONS
===================================================*/

.link-arrow {
    position: relative;
    &:after {
        font-family: 'FontAwesome';
        content: "\f178";
        margin-left: 5px;
    }
}

/*= Buttons =*/

.btn {
    display: inline-block;
    border: 1px solid $primary-light;
    background-color: $primary-light;
    color: $white;
    padding: 7px 30px 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    transition: all 0.15s ease-out;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    white-space: normal;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    -o-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-10deg);
    .fa {
        font-size: 14px;
        margin-left: 5px;
    }

    > span {
        display: inline-block;
        -moz-transform: skewX(10deg);
        -webkit-transform: skewX(10deg);
        -o-transform: skewX(10deg);
        -ms-transform: skewX(10deg);
        transform: skewX(10deg);
    }
    &:hover, &:focus {
        background-color: $primary;
        border-color: $primary;
        color: $white;
        outline: none;
    }
}

.btn-back .fa {
    margin-left: 0;
    margin-right: 5px;
}

.btn-white {
    background-color: $white;
    border-color: $white;
    &:hover, &:focus {
        background-color: $white;
        color: $primary;
    }
}

.btn-transparent {
    background-color: transparent;
    color: $dark;
}

/*= Multiple =*/

.btn + {
    .btn {
        margin-left: 10px;
    }
    .btn-block {
        margin-left: 0;
    }
}

/*= Social =*/

.btn-social {
    padding-left: 40px;
    position: relative;
    &:before {
        content: "";
        width: 32px;
        height: 32px;
        position: absolute;
        left: 0;
        top: -5px;
        background-image: url('../img/hexagon-inverse.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
    &:after {
        position: absolute;
        left: 8px;
        top: -3px;
        font-family: 'FontAwesome';
        color: $white;
        font-size: 18px;
    }
    &.btn-twitter:after {
        content: "\f099";
    }
    &.btn-facebook:after {
        content: "\f09a";
    }
    &.btn-linkedin:after {
        content: "\f0e1";
    }
    &.btn-youtube:after {
        content: "\f167";
    }
    &.btn-google:after {
        content: "\f0d5";
    }
}