/*===================================================
	BREADCRUMBS
===================================================*/

.breadcrumbs {
    padding: 15px 0;
    border-bottom: 1px solid $grey-30;
    position: relative;
    z-index: 99;
    + .block {
        margin-top: -57px;
        padding-top: 117px;
    }
    .fa {
        margin: 0 5px;
    }
}