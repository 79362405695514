/*===================================================
	CTA
===================================================*/

.cta {
    p {
        margin: 0;
        line-height: 40px;
    }
    ul {
        margin: 0;
        line-height: 40px;
        li {
            margin-left: 40px;
        }
        a .fa {
            color: $white;
            font-size: 20px;
            margin-right: 10px;
        }
    }
}