/*===================================================
	SWIPER
===================================================*/
.swiper-spacing {
    padding: 20px 50px;
    position: relative;

    .swiper-button-prev, .swiper-button-next {
        background-image: none;
    }
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }
    .swiper-button-prev .fa, .swiper-button-next .fa {
        font-size: 40px;
    }
}

.visual-swiper {
    position: relative;
    overflow: hidden;

    .swiper-button-prev,
    .swiper-button-next {
        color: #fff;
        font-size: 44px;
        background-image: none !important;
        padding: 20px;
        width: auto;
        top: 50%;
        margin-top: -32px;
    }
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }

    .swiper-slide {
        .bg-visual {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: $body-xl;
            text-shadow: 3px 3px rgba(0,0,0,0.15);

            &:after {
                content: "";
                position: absolute;
                z-index: 5;
                background-color: $black;
                opacity: 0.25;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .visual-swiper-content {
                position: relative;
                z-index: 10;
                padding-left: 50px;
                padding-right: 50px;
                max-width: 750px;
            }

            .title {
                font-weight: 700;
                font-size: 40px;
                line-height: 40px;
                margin: 0 0 20px;
            }
        }
    }
}

.product-swiper {
    .swiper-slide {
        height: auto;

        .box {
            height: 100%;
        }
    }
}
