/*===================================================
	FOOTER
===================================================*/

#footer {
    background-color: $primary;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;

    a {
        &:hover {
            color: $primary-light;
        }
    }
    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
}

/*= Nav =*/

.block.footer-nav {
    padding-bottom: 28px;
}

.footer-nav {
    p, ul {
        line-height: 34px;
    }
}

.footer-border {
    border-right: 1px solid $primary-alt;
}

.footer-nav {
    ul .fa {
        margin-left: 5px;
    }
    p {
        .fa {
            float: left;
            margin-top: 10px;
            margin-left: -22px;
        }
    }
    .btn {
        color: $primary;
        border-color: $primary;

        i {
            display: none;
        }
    }
}

/*= Social =*/

#footer .list-social li {
    float: left;
    padding: 0;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
    a {
        display: inline-block;
        width: 50px;
        height: 47px;
        text-align: center;
        line-height: 47px;
        font-size: 22px;
        background-image: url('../img/hexagon.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        color: $primary;
        .fa {
            margin-left: 0;
        }
        &:hover, &:focus {
            color: $dark;
        }
    }
}

/*= Bottom =*/

.footer-bottom {
    padding-left: 45px;
    padding-right: 45px;
    font-size: 18px;
    p {
        margin: 0;
    }
    ul {
        margin: 0;
        .fa {
            margin-right: 5px;
        }
    }
}

/*= Newsletter =*/

#footer .newsletter {
    background: url('../img/newsletter.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    h2 {
        font-size: 40px;
    }
    #Form_SubscribeForm {
        position: relative;
    }
    .form-control {
        padding-right: 50px;
        color: $white;
        border-bottom: 1px solid $white;
    }
    .bar {
        &:before, &:after {
            background: $white;
        }
    }
    .control-label {
        color: $white;
    }
    label.error {
        padding-right: 50px;
    }
    .btn {
        position: absolute;
        bottom: 30px;
        right: 0;
        background: transparent;
        border: none;
        padding: 7px 15px 10px;
        -webkit-transform: none;
        -o-transform: none;
        -ms-transform: none;
        transform: none;
    }
}