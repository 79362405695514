/*===================================================
	MAPS
===================================================*/

.map {
    position: relative;
    background-color: $grey-30;
}

#map {
    width: 100%;
    height: 500px;
}

.map-filter {
    position: absolute;
    left: 0;
    top: 65px;
    background-color: $white;
    padding: 24px;
    z-index: 999;
    width: 250px;
    height: 370px;
    h2 {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 12px;
    }
}

.list-locations {
    margin-top: 20px;
    height: 210px;
    overflow: auto;
    li label {
        font-weight: 400;
        position: relative;
        padding-left: 32px;
        input {
            display: none;
        }
        &:before {
            position: absolute;
            left: 0;
            top: 5px;
            content: "";
            width: 17px;
            height: 17px;
            background-image: url('../img/sprite-checkbox.png');
            background-repeat: no-repeat;
            background-position: 0 0;
        }
        &.checked:before {
            background-position: 0 -17px;
        }
    }
}