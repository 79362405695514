/*===================================================
	VISUAL
===================================================*/

.bg-visual {
    height: 300px;
    position: relative;
    background-color: $dark;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    .bg-visual-link {
        position: absolute;
        z-index: 30;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.visual-content {
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 50%;
    margin-top: 20px;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    h1, p {
        background-color: $white;
        padding: 0 40px;
        margin: 10px 0;
        font-size: 30px;
        line-height: 55px;
        font-weight: 600;
        -moz-transform: skewX(-10deg);
        -webkit-transform: skewX(-10deg);
        -o-transform: skewX(-10deg);
        -ms-transform: skewX(-10deg);
        transform: skewX(-10deg);
    }
    h1 span, p span {
        display: inline-block;
        -moz-transform: skewX(10deg);
        -webkit-transform: skewX(10deg);
        -o-transform: skewX(10deg);
        -ms-transform: skewX(10deg);
        transform: skewX(10deg);
    }
}


.visual {
    position: relative;
    background-color: blue;
    display: flex;
    margin-bottom: $spacing-md;

    &.visual-xl {
        min-height: $visual-height-xl;
    }
    &.visual-lg {
        min-height: $visual-height-lg;
    }
    &.visual-md {
        min-height: $visual-height-md;
    }
    &.visual-sm {
        min-height: $visual-height-sm;
    }
    &.visual-xs {
        min-height: $visual-height-xs;
    }

    &.visual-link {
        > a {
            &:first-child {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;
            }
        }
    }

    .hexagon-date {
        position: absolute;
        right: $spacing-md;
        top: $spacing-md;
    }

    &.visual-content-left-bottom {
        align-items: flex-end;
        justify-content: flex-start;
    }

    .hexagon {
        position: absolute;
        right: $spacing-sm;
        top: $spacing-sm;
        text-align: center;
        flex-direction: column;
        margin-right: 0;
        font-size: $body-sm;
    }

    .visual-content {
        transform: none;
        padding: $spacing-md;
        top: auto;
        left: auto;
        position: relative;
        width: 100%;

        &.visual-content-overlay {
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
            color: $white;
        }

        h1, h2, h3, h4 {
            margin-bottom: $spacing-sm;
            font-weight: 600;
        }

        p {
            background-color: transparent;
            transform: none;
            font-size: $body-md;
            font-weight: normal;
            padding: 0;
            line-height: 1.25;
            margin-bottom: 0;

            span {
                transform: none;
            }
        }
    }
}

/*= Large =*/
.bg-visual-lg {
    height: 650px;
    .visual-content {
        top: 50%;
        margin-top: 0;
        p, h1 {
            font-size: 40px;
            font-weight: 700;
        }
    }
}
