/*===================================================
	CARDS
===================================================*/

.card {
    background-color: $white;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba($black, 0.05);
}

.card-border {
    border-top: 1px solid $primary-light;
    &.color-green {
        border-top: 1px solid $green;
    }
    &.color-yellow {
        border-top: 1px solid $yellow;
    }
    &.color-light-blue {
        border-top: 1px solid $light-blue;
    }
    &.color-dark-blue {
        border-top: 1px solid $dark-blue;
    }
}

/*= Image =*/

.card-img-holder {
    position: relative;
}

.card-date, .card-category, .list-date {
    position: absolute;
}

.card-date {
    top: 10px;
    right: 10px;
    text-align: center;
    width: 50px;
    height: 47px;
    background-image: url('../img/hexagon.png');
    background-repeat: no-repeat;
    background-position: 50%;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 0;
}

.list-date {
    top: 10px;
    right: 10px;
    text-align: center;
    width: 50px;
    height: 47px;
    background-image: url('../img/hexagon.png');
    background-repeat: no-repeat;
    background-position: 50%;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 0;
    right: 25px;
}

.card-category {
    left: 0;
    bottom: 0;
    color: $white;
    padding: 7px 15px;
    background-color: rgba($primary-light, 0.85);
    &.dark {
        background-color: rgba($primary-alt, 0.85);
    }
}

/*= Content =*/

.card-content {
    padding: 24px;
}

.card-lg {
    &.card-content, .card-content {
        padding: 48px;
    }
}

.card-content > *:last-child {
    margin-bottom: 0;
}

/*= Title =*/

.card {
    h2 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 30px;
    }
    h3 {
        font-size: 24px;
        margin-bottom: 0;
        line-height: 30px;
        font-weight: 400;
        .fa {
            font-size: 18px;
        }
    }
}

.hexagon-img {
    position: relative;
    padding: 2px 0;

    .hexagon {
        position: absolute;
        top: -2px;
        left: 50%;
        margin-left: -50px;
    }
}

.hexagon {
    &.hexagon-default {
        background: url('../img/hexagon.png') no-repeat center center;
        height: 47px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $spacing-sm;

        span {
            display: block;
            text-transform: uppercase;
            color: $primary;
            font-weight: 600;
            line-height: 14px;
        }
    }

    &.hexagon-inverse {
        background: url('../img/hexagon-inverse.png') no-repeat center center;
        height: 47px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $spacing-sm;

        span {
            display: block;
            text-transform: uppercase;
            color: $white;
            font-weight: 600;
            line-height: 14px;
        }
    }
}