.videos {
    .title {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-md;

        h1, h2, h3 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .video {
        margin-bottom: $spacing-md;

        .video-title {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: $spacing-sm;
            background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
            color: $white;
        }
    }
}