/*===========================================================
	COOKIE
===========================================================*/
#cookie-notification {
    display: none;
    padding: 18px 0;
    position: fixed;
    z-index: 10000;
    width: 100%;
    color: $white;
    bottom: 0;
    background-color: $dark;
}
#cookie-notification p {
    margin: 0;
}