/*===================================================
	MODAL
===================================================*/

.modal-content {
    border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-box-shadow: 0 2px 4px rgba($black, 0.05);
    -webkit-box-shadow: 0 2px 4px rgba($black, 0.05);
    box-shadow: 0 2px 4px rgba($black, 0.05);
}

.modal-header h4 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
}

.modal-body dd {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    a img {
        margin-right: 10px;
    }
}