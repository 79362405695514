/*===================================================
	COLORS
===================================================*/

/*= Background colors =*/

.white-bg,
.bg-white {
    background-color: $white;
}

.dark-bg,
.bg-dark {
    background-color: $dark;
}

.primary-bg,
.bg-primary {
    background-color: $primary;
}

.gradient-bg {
    background: $primary-light;
    background: -moz-linear-gradient(-75deg, rgba($primary-light, 1) 0%, rgba($primary-light, 1) 50%, rgba($primary-light-alt, 1) 50%, rgba($primary-light-alt, 1) 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba($primary-light, 1)), color-stop(50%, rgba($primary-light, 1)), color-stop(50%, rgba($primary-light-alt, 1)), color-stop(100%, rgba($primary-light-alt, 1)));
    background: -webkit-linear-gradient(-75deg, rgba($primary-light, 1) 0%, rgba($primary-light, 1) 50%, rgba($primary-light-alt, 1) 50%, rgba($primary-light-alt, 1) 100%);
    background: -o-linear-gradient(-75deg, rgba($primary-light, 1) 0%, rgba($primary-light, 1) 50%, rgba($primary-light-alt, 1) 50%, rgba($primary-light-alt, 1) 100%);
    background: -ms-linear-gradient(-75deg, rgba($primary-light, 1) 0%, rgba($primary-light, 1) 50%, rgba($primary-light-alt, 1) 50%, rgba($primary-light-alt, 1) 100%);
    background: linear-gradient(105deg, rgba($primary-light, 1) 0%, rgba($primary-light, 1) 50%, rgba($primary-light-alt, 1) 50%, rgba($primary-light-alt, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$primary-light', endColorstr='#6fab26', GradientType=1);
}

.shade-bg {
    background-color: rgba(0, 0, 0, 0.1);
}

/*= Text colors =*/

.grey-text,
.text-grey {
    color: $grey-40;
}

.white-text,
.text-white {
    color: $white;

    a {
        color: $white;

        &:hover, &:focus {
            color: $primary-alt;
        }
    }
}

// Colors

.color-green .btn {
    border: 1px solid $green;
    background-color: $green;
}

.color-yellow .btn {
    border: 1px solid $yellow;
    background-color: $yellow;
}

.color-light-blue .btn {
    border: 1px solid $light-blue;
    background-color: $light-blue;
}

.color-dark-blue .btn {
    border: 1px solid $dark-blue;
    background-color: $dark-blue;
}