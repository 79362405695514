/*===================================================
	LIST GROUP
===================================================*/

.list-group-item {
    background-color: transparent;
    border: none;
    padding: 20px 0;
    border-top: 1px solid rgba($black, 0.1);
    margin: 0;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    .fa {
        margin-left: 5px;
    }
}

.list-horizontal {
    display: flex;
}

.list-brands {
    display: flex;
    justify-content: center;

    .list-group-item {
        border-top: none;
        margin: 0 $spacing-md;
        font-size: $body-xl;

        a {
            color: $dark;

            i {
                transition: $transition-md;
            }

            &:hover {
                i {
                    color: $primary;
                }
            }
        }
    }
}

.list-news {
    .list-group-item {
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .news-content {
            padding: $spacing-sm;
            flex-shrink: 1;

            a {
                color: $dark;
            }

            h4 {
                margin-top: 0;
                font-weight: 600;
            }

            p {
                margin-bottom: 0;
            }
        }

        .news-image {
            position: relative;
            min-width: 200px;

            .hexagon {
                font-size: $body-sm;
                margin-right: 0;
                position: absolute;
                right: $spacing-xs;
                top: $spacing-xs;
                flex-direction: column;
            }
        }
    }
}

.card .list-group-title {
    color: $primary;
    font-size: 24px;
    line-height: 40px;
    margin: 0;
    font-weight: 300;
    padding: 10px 24px;
    list-style: none;
}

/*= Link list group =*/

a.list-group-item {
    color: $dark;
}

/*= List group in card =*/

.card {
    .list-group-item {
        color: $dark;
        padding: 12px 24px;
        border: none;
        border-top: 1px solid rgba($black, 0.1);
        margin: 0;
        .fa {
            color: $primary-light;
            transition: all 0.15s ease-out;
        }
    }
    a.list-group-item {
        &:hover, &:focus, &.active {
            background-color: $primary-light;
            color: $white;
            border-top-color: rgba($black, 0.1);
            border-bottom-color: rgba($black, 0.1);
        }
        &:hover .fa, &:focus .fa, &.active .fa {
            color: $white;
        }
    }
    .list-group-item {
        &.color-green:after,
        &.color-yellow:after,
        &.color-light-blue:after,
        &.color-dark-blue:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
            width: 2px;
            background: $black;
        }
        &.color-green:after {
            background-color: $green;
        }
        &.color-yellow:after {
            background-color: $yellow;
        }
        &.color-light-blue:after {
            background-color: $light-blue;
        }
        &.color-dark-blue:after {
            background-color: $dark-blue;
        }
    }
}