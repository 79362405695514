/*===================================================
	BLOCKS
===================================================*/

.block {
    padding: 60px 0;
}

.block-md {
    padding: 48px 0;
}

.block-sm {
    padding: 24px 0;
}