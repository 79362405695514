/*===================================================
	CONTENT
===================================================*/

.content {
    margin-bottom: 30px;
    border-top: 1px solid $grey-30;
    border-bottom: 1px solid $grey-30;
}

.content-text {
    h2 {
        font-size: 24px;
        font-weight: 400;
        color: $primary;
    }
    padding: 24px 48px;
    min-height: 200px !important;
    &.left {
        padding-right: 18px;
    }
    &.right {
        padding-left: 18px;
    }
}

/*= Image =*/

.content-img {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}