/*===================================================
	DESKTOP
===================================================*/
@media (min-width: 1200px) {
    /*= Header =*/
    .off-canvas-toggle {
        display: none;
    }
    #header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 43px;
        transition: all 0.15s ease-out;
        z-index: 1001;
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 1;
            transition: all 0.15s ease-out;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 2%, rgba(0, 0, 0, 0) 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.15)), color-stop(2%, rgba(0, 0, 0, 0.15)), color-stop(100%, rgba(0, 0, 0, 0)));
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 2%, rgba(0, 0, 0, 0) 100%);
            background: -o-linear-gradient(top, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 2%, rgba(0, 0, 0, 0) 100%);
            background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 2%, rgba(0, 0, 0, 0) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 2%, rgba(0, 0, 0, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26000000', endColorstr='#00000000',GradientType=0 );
        }
        > * {
            position: relative;
            z-index: 2;
        }
    }
    /*= Logo =*/
    a.logo {
        position: fixed;
        left: 45px;
        top: 30px;
        z-index: 1010;
        img {
            display: block;
        }

        &:after {
            position: absolute;
            content: "";
            background-image: url('../img/logo-matermaco-dark.png');
            background-repeat: no-repeat;
            background-position: 50%;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            transition: all 0.15s ease-out;
        }
        &.fixed {
            top: 12px;

            &:after {
                opacity: 1;
            }
        }
    }

    /*= Fixed =*/
    #header.fixed {
        background-color: $white;
        padding: 20px 45px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        &:after {
            opacity: 0;
        }
    }
    /*= nav =*/
    #nav {
        font-size: 18px;
        font-weight: 600;
        margin: -5px -10px -5px 0;
        float: right;
        list-style: none;
        li {
            float: left;
            margin-left: -1px;
            a {
                display: block;
                color: $white;
                padding: 5px 15px;
                border: 1px solid transparent;
                transition: all 0.15s ease-out;
                -moz-transform: skewX(-10deg);
                -webkit-transform: skewX(-10deg);
                -o-transform: skewX(-10deg);
                -ms-transform: skewX(-10deg);
                transform: skewX(-10deg);
                span {
                    display: inline-block;
                    -moz-transform: skewX(10deg);
                    -webkit-transform: skewX(10deg);
                    -o-transform: skewX(10deg);
                    -ms-transform: skewX(10deg);
                    transform: skewX(10deg);
                }
                &:hover {
                    border-color: $white;
                }
            }
            &.active a {
                border-color: $white;
            }
            a .fa {
                margin-right: 5px;
            }
        }
        .dropdown-menu {
            background: none;
            border: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            right: 0;
            left: auto;
            min-width: 0;
            li {
                display: block;
                float: none;
                a:hover {
                    background: none;
                }
            }
        }
    }
    /*= Fixed =*/
    #header.fixed #nav li {
        a {
            color: $dark;
            &:hover {
                border-color: $dark;
            }
        }
        &.active a {
            border-color: $dark;
        }
    }
}

/*===================================================
	TABLET
===================================================*/
@media (max-width: 1200px) {
    /*= Default =*/
    #wrapper {
        width: 100%;
        position: relative;
        transition: all 0.5s ease-out;
    }
    .full-overlay {
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        z-index: 1002;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.15s ease-out;
    }
    /*= Logo =*/
    .logo {
        position: fixed;
        width: 100%;
        display: block;
        text-align: center;
        background-color: $primary;
        padding: 10px;
        z-index: 1010;
        left: 0;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }
    /*= Header =*/
    a.off-canvas-toggle {
        position: fixed;
        z-index: 1100;
        right: 20px;
        bottom: 20px;
        background-color: $primary-light;
        color: $white;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        font-size: 25px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        &:focus {
            color: $white;
        }
    }
    #header {
        position: fixed;
        z-index: 1005;
        height: 100%;
        width: 300px;
        background-color: $light;
        top: 0;
        right: -300px;
        padding-top: 46px;
        transition: all 0.5s ease-out;
    }
    /*= Nav =*/
    #nav {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: block;
            float: none;
            a {
                display: block;
                color: $dark;
                padding: 7px 10px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            &.active a {
                color: $white;
                background-color: $primary-light;
                border-bottom-color: transparent;
            }
        }
    }
    /*= Off canvas =*/
    .off-canvas-open {
        #wrapper {
            margin-left: -300px;
        }
        .full-overlay {
            height: 100%;
            opacity: 1;
        }
        #header {
            right: 0;
        }
    }
    /*= Main =*/
    #main {
        padding-top: 46px;
    }
    /*= Visual =*/
    .visual-content {
        margin-top: 0;
        left: 5%;
        width: 90%;
        -moz-transform: translateX(0) translateY(-50%);
        -webkit-transform: translateX(0) translateY(-50%);
        -o-transform: translateX(0) translateY(-50%);
        -ms-transform: translateX(0) translateY(-50%);
        transform: translateX(0) translateY(-50%);
    }
    .bg-visual-lg {
        height: 300px;
        .visual-content p {
            font-size: 22px;
            line-height: 40px;
            font-weight: 600;
        }
    }
    .visual-content p {
        font-size: 22px;
        line-height: 40px;
        font-weight: 600;
    }
    /*= Footer =*/
    #footer .list-social li {
        margin-right: 5px;
    }
}

/*===================================================
	MOBILE
===================================================*/
@media (max-width: 768px) {
    /*= Background colors =*/
    .gradient-bg {
        background: $primary-light !important;
    }
    /*= Visual =*/
    .bg-visual-lg .visual-content p, .visual-content p {
        font-size: 18px;
        line-height: 30px;
    }
    /*= Cta =*/
    .cta ul li {
        margin-left: 0;
        margin-right: 20px;
        margin-top: 20px;
    }
    /*= Blocks =*/
    .block {
        padding: 40px 0;
    }
    .block-md {
        padding: 38px 0;
    }
    .block-sm {
        padding: 14px 0;
    }
    /*= Footer =*/
    .footer-border {
        border: none;
    }
    .footer-bottom {
        padding: 15px;
        padding-bottom: 100px;
    }
    .list-inline > li {
        padding: 15px;
    }
    /*= googlemaps =*/
    .map-filter.height {
        top: 0;
        height: auto;
        width: 100%;
        h2 {
            display: inline-block;
            &.dealer {
                cursor: pointer;
                line-height: 14px;
                padding: 15px 15px 30px 15px;
            }
        }
    }

    .list-news {
        .list-group-item {
            flex-direction: column;

            .news-image {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }

    .list-brands {
        flex-direction: column;
        align-items: center;
    }
}