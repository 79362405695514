/*===================================================
	BOX
===================================================*/

.box {
    border: 1px solid $white;
    padding: 25px;
    text-align: center;
}

.box-logo {
    display: block;
    text-align: center;

    &:hover {
        color: $white !important;
        border-color: $primary-light;
    }

    img {
        display: block;
        margin: 0 auto;
        max-height: 80px;
        min-height: 80px;
        margin-bottom: 30px;
    }
}
