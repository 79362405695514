/*===================================================
	DEFAULT
===================================================*/

html, body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: $dark;
}

/*===================================================
	TITLES
===================================================*/

h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 20px;
}

h2 {
    font-size: 24px;
    font-weight: 600;
}

h3 {
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 400;
}

h1 a, h2 a, h3 a {
    color: $dark;
}

/*===================================================
	LINKS
===================================================*/

a {
    color: $primary-light;
    transition: all 0.15s ease-out;
    &:hover, &:focus {
        color: $primary;
        text-decoration: none;
    }
}

/*===================================================
	MARGINS
===================================================*/

p, ul, ol, table {
    margin-bottom: 20px;
}

/*===================================================
	TEXT
===================================================*/

/*= Sizes =*/

.text-md {
    font-size: 24px;
    line-height: 32px;
}

.text-lg {
    font-size: 40px;
    line-height: 40px;
    font-weight: 300;
}

.text-sm {
    font-size: 12px;
    line-height: 20px;
}

.text-primary {
    color: $primary;
}

/*===================================================
	IMAGES
===================================================*/

img {
    height: auto;
    max-width: 100%;
}