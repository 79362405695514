
$body-xl: 24px;
$body-lg: 20px;
$body-md: 16px;
$body-sm: 14px;
$body-xs: 10px;

$primary-light-alt: #6fab26;
$primary-light: #76b729;
$primary: #1e824c;
$primary-alt: #1b7545;
$primary-dark: #06371d;

$secondary: #efefef;
$secondary-alt: #ffffff;

$black: #000000;
$dark: #4d4d4d;
$light: #efefef;
$white: #ffffff;

$grey-10: #f8f8f8;
$grey-20: #e8e8e8;
$grey-30: #dadada;
$grey-40: #b3b3b3;
$grey-50: #a3a3a3;

$green: #00A652;
$yellow: #FCB062;
$light-blue: #00B5E0;
$dark-blue: #6F859C;

$error: #b74029;

$spacing-xl: 50px;
$spacing-lg: 40px;
$spacing-md: 30px;
$spacing-sm: 15px;
$spacing-xs: 10px;

$visual-height-xl: 400px;
$visual-height-lg: 300px;
$visual-height-md: 250px;
$visual-height-sm: 200px;
$visual-height-xs: 100px;

$transition-md: all 0.2s ease-out;