/*===================================================
	CHROME HACK
===================================================*/

input.form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $light inset;
}

.white-bg input.form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $white inset;
}

/*===================================================
	FORM GROUP
===================================================*/

.form-group {
    &.text, &.textarea {
        position: relative;
        margin: 15px 0 30px;
    }
    &.text label, &.textarea label {
        position: absolute;
        top: 2px;
        left: 0;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 5px;
        margin: 0;
        transition: all 0.15s ease-out;
        cursor: text;
    }
    .form-control.error ~ label {
        color: $error !important;
    }
    &.text label.error, &.textarea label.error {
        left: auto;
        right: 0;
        font-size: 12px;
    }
    &.bar {
        position: relative;
        display: block;
    }
}

/*= Bar =*/

.bar {
    &:before, &:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0;
        position: absolute;
        background-color: $primary-light;
        transition: 0.2s ease all;
    }
}

.form-control.error ~ .bar {
    &:before, &:after {
        background-color: $error;
    }
}

.bar {
    &:before {
        left: 50%;
    }
    &:after {
        right: 50%;
    }
}

/*= Focus =*/

.form-group {
    &.text .form-control {
        &:focus ~ label, &.has-value ~ label {
            top: -25px;
            font-size: 12px;
            color: $primary-light;
            cursor: default;
        }
    }
    &.textarea .form-control {
        &:focus ~ label, &.has-value ~ label {
            top: -25px;
            font-size: 12px;
            color: $primary-light;
            cursor: default;
        }
    }
    .form-control:focus ~ .bar {
        &:before, &:after {
            width: 50%;
        }
    }
}

/*===================================================
	FORM CONTROL
===================================================*/

.form-control {
    border: none;
    border-bottom: 1px solid $grey-50;
    height: 50px;
    font-size: 16px;
    color: $dark;
    padding: 10px 5px;
    background-color: transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    &:focus {
        outline: none;
        border-color: $grey-50;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

/*===================================================
	INPUT GROUP
===================================================*/

.input-group .btn {
    padding: 0;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    -moz-transform: skewX(0);
    -webkit-transform: skewX(0);
    -o-transform: skewX(0);
    -ms-transform: skewX(0);
    transform: skewX(0);
    .fa {
        font-size: 22px;
        margin: 5px 0 0 -2px;
    }
}

li {
    list-style-type: none;
}

.checkbox {
    display: inline-block;
    margin-right: 15px !important;
}