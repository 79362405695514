/*===================================================
	GRID
===================================================*/

/*= Small gutter =*/

.row.small-gutter {
    margin-left: -5px;
    margin-right: -5px;
    *[class^="col"] {
        padding-left: 5px;
        padding-right: 5px;
    }
}

/*= Extra gutter =*/

.extra-gutter-left {
    padding-left: 18px;
}

.extra-gutter-right {
    padding-right: 18px;
}

.row-flex {
    display: flex;

    .content-img {
        height: 100%;
    }
}

@media (max-width: 768px) {
    .row-flex {
        display: block;

        .content-img {
            min-height: 250px;
        }
    }
}